import ApiConfig from '../config/ApiConfig';
import {
  STATUS_200,
  STATUS_300,
  STATUS_401,
  STATUS_403,
} from '../data/constants';
import axios from 'axios';
import { errorMessage } from '../lib/helper';
import { toast } from 'react-toastify';
import { saved, saving } from '../redux/saveSlice';

const showErrorMessage = (e) => {
  if (e?.data?.status !== STATUS_401 && e?.data?.status !== STATUS_403) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};
export const getMarketListService = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.listMarket}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return resp?.data?.data;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
export const createMarketService = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(`${ApiConfig.createMarket}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      // toast.success(resp?.data?.message);
      dispatch(saved());
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
export const updateMarketService = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(`${ApiConfig.updateMarket}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      // toast.success(resp?.data?.message);
      dispatch(saved());
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
export const getCompetitorListService = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.listCompetitors}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return resp?.data?.data;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
export const createCompetitorService = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(`${ApiConfig.createCompetitor}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      // toast.success(resp?.data?.message);
      dispatch(saved());
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
export const createMarketSubCategoryService = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(
      `${ApiConfig.createMarketSubCategory}`,
      payload
    );
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      // toast.success(resp?.data?.message);
      dispatch(saved());
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
export const getMarketSubCategoriesService = async (payload) => {
  try {
    const resp = await axios.post(
      `${ApiConfig.listMarketSubCategories}`,
      payload
    );
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return resp?.data?.data;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};

export const deleteMarketService = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.delete(`${ApiConfig.deleteMarket}`, {
      data: payload,
    });
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      // toast.success(resp?.data?.message);
      dispatch(saved());
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};

export const updateMarketSubCategoryService = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(
      `${ApiConfig.updateMarketSubCategory}`,
      payload
    );
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      // toast.success(resp?.data?.message);
      dispatch(saved());
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
export const deleteMarketSubCategoryService = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.delete(`${ApiConfig.deleteMarketSubCategory}`, {
      data: payload,
    });
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      // toast.success(resp?.data?.message);
      dispatch(saved());
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
export const createStrengthWeaknessService = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(
      `${ApiConfig.createStrengthWeakness}`,
      payload
    );
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      // toast.success(resp?.data?.message);
      dispatch(saved());
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};

export const deleteStrengthWeaknessService = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.delete(`${ApiConfig.deleteStrengthWeakness}`, {
      data: payload,
    });
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      // toast.success(resp?.data?.message);
      dispatch(saved());
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
export const fetchMarketsWithSubcategoriesService = async (payload) => {
  try {
    const resp = await axios.post(
      `${ApiConfig.marketsWithSubCategories}`,
      payload
    );
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return resp?.data?.data;
    } else {
      throw resp;
    }
  } catch (e) {
    // showErrorMessage(e);
    return false;
  }
};

export const updateCompetitorService = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(`${ApiConfig.updateCompetitor}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      // toast.success(resp?.data?.message);
      dispatch(saved());
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};

export const linkCompetitorToMarketService = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(
      `${ApiConfig.linkCompetitorToMarket}`,
      payload
    );
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      // toast.success(resp?.data?.message);
      dispatch(saved());
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};

export const getCompetitorDetailsService = async (payload) => {
  try {
    const resp = await axios.post(
      `${ApiConfig.listCompetitorDetails}`,
      payload
    );
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return resp?.data?.data;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
export const updateStrengthAndWeaknessService = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(
      `${ApiConfig.updateStrengthWeakness}`,
      payload
    );
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      // toast.success(resp?.data?.message);
      dispatch(saved());
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};

export const saveCompSubCatQuestionDtlService = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(
      `${ApiConfig.saveCompSubCatDetails}`,
      payload
    );
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      // toast.success(resp?.data?.message);
      dispatch(saved());
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
export const getAssessmentListService = async () => {
  try {
    const resp = await axios.post(`${ApiConfig.listAssessment}`);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return resp?.data?.data;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};

export const addAssessmentService = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.post(`${ApiConfig.addAssessment}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      // toast.success(resp?.data?.message);
      dispatch(saved());
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
export const deleteCompSubCatService = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.delete(`${ApiConfig.delteSubCatProfile}`, {
      data: payload,
    });
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      // toast.success(resp?.data?.message);
      dispatch(saved());
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
export const deleteCompetitorService = async (payload, dispatch) => {
  try {
    dispatch(saving());
    const resp = await axios.delete(`${ApiConfig.deleteCompetitor}`, {
      data: payload,
    });
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      // toast.success(resp?.data?.message);
      dispatch(saved());
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    showErrorMessage(e);
    return false;
  }
};
